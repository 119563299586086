import './App.scss';
import './_globals.scss'
import InDevelopmentHomePage from './pages/InDevelopmentHomePage.mjs';


const App = () => {
  return (
    <div className="App rf">
      <InDevelopmentHomePage />
    </div>
  );
}

export default App;
